import gql from 'graphql-tag';
import React, {useEffect, useState} from 'react';
import {
  Query,
  QueryResult,
} from 'react-apollo';
import ApolloProvider from '../../contextProviders/ApolloProvider';
import {
  generateStringLocationId,
  generateStringProductId,
} from '../../graphQL/dataFetchers/Utils';
import {
  Location,
  Product,
  ProductClass,
  TradeDirection,
} from '../../graphQL/graphQLTypes';
import { Target } from '../../Utils';
import Tooltip from '../../viz/Tooltip';
import { ChartTitle } from '../../viz/VizGrid';

const query = gql`
  query titleInformation($locationId: ID!, $otherLocationId: ID!, $productId: ID!) {
    location(id: $locationId) {
      id
      shortName
    }
    otherLocation: location(id: $otherLocationId) {
      id
      shortName
    }
    product(id: $productId) {
      id
      shortName
    }
  }
`;

interface SuccessResponse {
  location: {
    id: Location['id'],
    shortName: Location['shortName'],
  } | null;
  otherLocation: {
    id: Location['id'],
    shortName: Location['shortName'],
  } | null;
  product: {
    id: Product['id'],
    shortName: Product['shortName'],
  } | null;
}

interface Variables {
  locationId: string;
  otherLocationId: string;
  productId: string;
}

type Result = QueryResult<SuccessResponse, Variables>;

interface Props {
  country: number | undefined;
  otherCountry: number | undefined;
  product: number | undefined;
  tradeDirection: TradeDirection;
  startYear: number;
  endYear: number;
  productClass: ProductClass;
  target: Target;
  setTitle?: (val: string) => void;
}

const ExploreGraphTitle = (props: Props) => {
  const {
    country, otherCountry, product, tradeDirection, startYear, endYear, productClass,
    target, setTitle,
  } = props;

  const [graphTitle, setGraphTitle] = useState<string>('');

  const updateGraphTitle = (val: string) => {
    if (val !== graphTitle) {
      setGraphTitle(val);
    }
  };

  useEffect(() => {
    if (setTitle !== undefined) {
      setTitle(graphTitle);
    }
  }, [graphTitle]);

  const tradeWord = (tradeDirection === TradeDirection.export) ? 'export' : 'import';
  const preposition = (tradeDirection === TradeDirection.export) ? 'to' : 'from';

  const locationId = country === undefined ? '' : generateStringLocationId(country);
  const otherLocationId = otherCountry === undefined ? '' : generateStringLocationId(otherCountry);
  const productId = product === undefined ? '' : generateStringProductId({productClass, id: product});

  const variables: Variables = {locationId, productId, otherLocationId};

  const renderProp = (result: Result) => {
    const {loading, error, data} = result;
    if (loading === true) {
      return null;
    } else if (error !== undefined) {
      console.error(error);
      return null;
    } else if (data !== undefined) {
      const { location, product: productData, otherLocation } = data;
      let title: string;
      if (location !== null && productData === null) {
        if (otherLocation !== null) {
          // tslint:disable-next-line:max-line-length
          title = `What did ${location.shortName} ${tradeWord} ${preposition} ${otherLocation.shortName} between ${startYear} and ${endYear}?`;
        } else {
          if (target === Target.Product) {
            title = `What did ${location.shortName} ${tradeWord} between ${startYear} and ${endYear}?`;
          } else {
            title = `Where did ${location.shortName} ${tradeWord} ${preposition} between ${startYear} and ${endYear}?`;
          }
        }
      } else if (location === null && productData !== null) {
        const tradeWordPastTense = (tradeDirection === TradeDirection.export) ? 'exported' : 'imported';
        title = `Who ${tradeWordPastTense} ${productData.shortName} between ${startYear} and ${endYear}?`;
      } else if (location !== null && productData !== null) {
        // tslint:disable-next-line:max-line-length
        title = `Where did ${location.shortName} ${tradeWord} ${productData.shortName} ${preposition} between ${startYear} and ${endYear}?`;
      } else {
        title = '';
      }
      updateGraphTitle(title);
      return (
        <Tooltip
          explanation={title}
          title={title}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <ChartTitle>
      <ApolloProvider>
        <Query
          query={query}
          children={renderProp}
          variables={variables}
        />
      </ApolloProvider>
    </ChartTitle>
  );
};

export default ExploreGraphTitle;
