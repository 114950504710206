import {
  ProductClass,
} from '../../graphQL/graphQLTypes';
import getYearlyDataCache, {
  IBaseState,
} from '../../sharedComponents/yearlyDataCache';
import {
  apiBaseURL,
  failIfValidOrNonExhaustive,
  fetchJSON,
  ICCPYDatum,
  ProductMetadatumLevel as Level,
} from '../../Utils';
import {
  IWorkerRootState,
} from '../workerRootState';

export type IState = IBaseState<ICCPYDatum>;

interface IHashInput {
  productClass: ProductClass;
  country: number;
  partner: number;
  level: Level;
}

interface IAPIResponse {
  data: ICCPYDatum[];
}

export const defaultHashFunction = ({productClass, country, partner, level}: IHashInput) => {
  let sharedLevel: Level;
  if (level === Level.section || level === Level.twoDigit || level === Level.fourDigit) {
    sharedLevel = Level.fourDigit;
  } else if (level === Level.sixDigit) {
    sharedLevel = Level.sixDigit;
  } else {
    failIfValidOrNonExhaustive(level, 'Invalid product level');
    // The following lines will never be executed:
    sharedLevel = Level.fourDigit;
  }
  return `${productClass}_${country}_${partner}_${sharedLevel}`;
};

const FETCH_BEGIN = 'COUNTRY_COUNTRY_PRODUCT_YEAR_BY_COUNTRY_COUNTRY_FETCH_BEGIN';
const FETCH_SUCCESS = 'COUNTRY_COUNTRY_PRODUCT_YEAR_BY_COUNTRY_COUNTRY_FETCH_SUCCESS';
const FETCH_FAIL = 'COUNTRY_COUNTRY_PRODUCT_YEAR_BY_COUNTRY_COUNTRY_FETCH_FAIL';
const FETCH_IF_NEEDED = 'COUNTRY_COUNTRY_PRODUCT_YEAR_BY_COUNTRY_COUNTRY_FETCH_IF_NEEDED';

const {
  reducer,
  fetchDataEpic,
  fetchIfNeeded,
  getDataStatusSelector,
  getYearsStatusSelector,
} = getYearlyDataCache<
  IWorkerRootState,
  ICCPYDatum,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHashInput,
  IAPIResponse
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  outerHashFunction: defaultHashFunction,
  getCacheFromRootState: (rootState: IWorkerRootState) => rootState.countryCountryProductYearByCountryCountry,
  getFetchPromise: ({productClass, country, partner, level}: IHashInput) => {
    let productClassPhrase: string;
    if (productClass === ProductClass.HS) {
      productClassPhrase = 'hs_products';
    } else if (productClass === ProductClass.SITC) {
      productClassPhrase = 'sitc_products';
    } else {
      failIfValidOrNonExhaustive(productClass, 'Invalid product class');
      productClassPhrase = '';
    }

    let fetchLevelPhrase: string;
    if (level === Level.section || level === Level.twoDigit || level === Level.fourDigit) {
      fetchLevelPhrase = '4digit';
    } else if (level === Level.sixDigit) {
      fetchLevelPhrase = '6digit';
    } else {
      failIfValidOrNonExhaustive(level, 'Invalid product metadatum level');
      // The following lines will never be executed:
      fetchLevelPhrase = '';
    }
    return fetchJSON<IAPIResponse>(
      `${apiBaseURL}/data/location/${country}/${productClassPhrase}_by_partner/${partner}/?level=${fetchLevelPhrase}`,
    );
  },
  getDataFromAPIResponse: ({data}: IAPIResponse) => data,
});

export default reducer;
export {fetchDataEpic, fetchIfNeeded, getDataStatusSelector, getYearsStatusSelector};
