import {
  TradeDirection,
  TradeFlow,
} from '../../graphQL/graphQLTypes';
import {
  InflationAdjustment,
  PopulationAdjustment,
} from './Utils';

const getFactor = (
    inflationAdjustment: InflationAdjustment,
    populationAdjustment: PopulationAdjustment,
    tradeDirection: TradeDirection,
    tradeFlow: TradeFlow,
  ): string => {

  let result: string;
  if (inflationAdjustment === InflationAdjustment.Adjusted) {
    if (populationAdjustment === PopulationAdjustment.Adjusted) {
      // result = perCapitaConstant;
      if (tradeDirection === TradeDirection.export && tradeFlow === TradeFlow.Gross) {
        result = __lexiconText('tooltipLabels.perCapitaConstantGrossExport');
      } else if (tradeDirection === TradeDirection.export && tradeFlow === TradeFlow.Net) {

        result = __lexiconText('tooltipLabels.perCapitaConstantNetExport');
      } else if (tradeDirection === TradeDirection.import && tradeFlow === TradeFlow.Gross) {

        result = __lexiconText('tooltipLabels.perCapitaConstantGrossImport');
      } else {

        result = __lexiconText('tooltipLabels.perCapitaConstantNetImport');
      }
    } else {
      if (tradeDirection === TradeDirection.export && tradeFlow === TradeFlow.Gross) {
        result = __lexiconText('tooltipLabels.constantGrossExport');
      } else if (tradeDirection === TradeDirection.export && tradeFlow === TradeFlow.Net) {

        result = __lexiconText('tooltipLabels.constantNetExport');
      } else if (tradeDirection === TradeDirection.import && tradeFlow === TradeFlow.Gross) {

        result = __lexiconText('tooltipLabels.constantGrossImport');
      } else {

        result = __lexiconText('tooltipLabels.constantNetImport');
      }
    }
  } else {
    if (populationAdjustment === PopulationAdjustment.Adjusted) {
      if (tradeDirection === TradeDirection.export && tradeFlow === TradeFlow.Gross) {
        result = __lexiconText('tooltipLabels.perCapitaCurrentGrossExport');
      } else if (tradeDirection === TradeDirection.export && tradeFlow === TradeFlow.Net) {

        result = __lexiconText('tooltipLabels.perCapitaCurrentNetExport');
      } else if (tradeDirection === TradeDirection.import && tradeFlow === TradeFlow.Gross) {

        result = __lexiconText('tooltipLabels.perCapitaCurrentGrossImport');
      } else {

        result = __lexiconText('tooltipLabels.perCapitaCurrentNetImport');
      }
    } else {
      if (tradeDirection === TradeDirection.export && tradeFlow === TradeFlow.Gross) {
        result = __lexiconText('tooltipLabels.currentGrossExport');
      } else if (tradeDirection === TradeDirection.export && tradeFlow === TradeFlow.Net) {

        result = __lexiconText('tooltipLabels.currentNetExport');
      } else if (tradeDirection === TradeDirection.import && tradeFlow === TradeFlow.Gross) {

        result = __lexiconText('tooltipLabels.currentGrossImport');
      } else {

        result = __lexiconText('tooltipLabels.currentNetImport');
      }
    }

  }

  return result;
};

export default getFactor;
