import React from 'react';
import styled from 'styled-components';
import Tooltip from '../../sharedComponents/GeneralGraphTooltip';
import {
  vizGridZIndices,
} from '../../viz/VizGrid';
import {
  IRibbon,
  ITooltipMapDatum,
  IYearDataPoint,
} from '../../workerStore/stack/Utils';
import {
  StackChartContainer,
  StackChartRoot,
} from '../Grid';
import {
  getTooltipInput,
} from './Utils';

const TooltipContainerElement = styled(StackChartContainer)`
  pointer-events: none;
  z-index: ${vizGridZIndices.tooltipsContainer};
`;
const TooltipsContainer = styled(StackChartRoot)`
  position: relative;
`;

interface IProps {
  highlighted: number | undefined;
  ribbons: IRibbon[];
  tooltipMap: Record<string, ITooltipMapDatum>;
  hovered: IYearDataPoint | undefined;
  selected: IYearDataPoint | undefined;
}

export default class extends React.PureComponent<IProps> {
  render() {
    const {
      hovered, selected, tooltipMap, ribbons, highlighted,
    } = this.props;

    const tooltipInput = getTooltipInput(tooltipMap, ribbons, hovered, selected, highlighted);

    const tooltipElems = tooltipInput.map(
      ({shortLabel, color, upperY, x, year, ribbonId, tooltipInfo}) => {

        return (
          <Tooltip title={shortLabel} rows={tooltipInfo}
            x={x} y={upperY} color={color}
            key={`hover-tooltip-${ribbonId}-${year}`}/>
        );
    });
    return (
    <TooltipContainerElement>
      <TooltipsContainer>
        {tooltipElems}
      </TooltipsContainer>
    </TooltipContainerElement>
    );
  }
}
