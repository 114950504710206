import React from 'react';
import {
  connect,
  DispatchProp,
  MapStateToProps,
} from 'react-redux';
import memoize from '../../memoize';
import {
  IRootState,
} from '../../rootState';
import {
  fetchIfNeeded,
  getDataSelector as getMetadataSelector,
  IMetadatum,
} from '../../sharedData/newCountryMetadata';
import {
  CountryMetadatumLevel,
  ILoadable,
  LoadableStatus,
  regionColorMap,
} from '../../Utils';
import Choice from './Choice';
import Selector, {
  IChoice,
  IChoiceGetElementInput,
  IRenderChoicesInput,
} from './index';
import RegionContainer from './RegionContainer';
import {
  SpotlightMode,
  unselectedCategoryColor,
} from './Utils';

const regionSelectorRenderProp = (input: IRenderChoicesInput) => {

  const {
    choices, deselectedCategories, onShowHideClick, onIsolateClick,
    spotlightMode,
  } = input;

  const choiceElems = choices.map(choice => {
    return (
        <Choice
          choice={choice}
          onShowHideClick={onShowHideClick}
          onIsolateClick={onIsolateClick}
          isEnabled={true}
          spotlightMode={spotlightMode}
          deselectedCategories={deselectedCategories}
          key={`choice-${choice.value}`}
        />
    );
  });
  return (
    <>
      {choiceElems}
    </>
  );
};

interface ISelectorInfo {
  choices: IChoice[];
  allPossibleValues: number[];
}
const unmemoizeGetSelectorInfo = (inputStatus: ILoadable<Map<number, IMetadatum>>): ILoadable<ISelectorInfo> => {
  if (inputStatus.status === LoadableStatus.Initial) {
    return {status: LoadableStatus.Initial};
  } else if (inputStatus.status === LoadableStatus.Loading) {

    return {status: LoadableStatus.Loading};
  } else if (inputStatus.status === LoadableStatus.NotPresent) {

    return {status: LoadableStatus.NotPresent};
  } else {
    const {data} = inputStatus;
    const metadataList = [...data.values()];
    const regionMetadata = metadataList.filter(({level}) => level === CountryMetadatumLevel.region);
    const choices: IChoice[] = regionMetadata.map(({id, name_short_en}) => {
      const regularColor = regionColorMap.get(id);
      if (regularColor === undefined) {
        throw new Error('Cannot find color for region ' + id);
      }
      return {
        color: regularColor,
        value: id,
        label: name_short_en,
        getElement({isSelected}: IChoiceGetElementInput) {
          const color = isSelected ? regularColor : unselectedCategoryColor;
          return (
            <RegionContainer color={color} text={name_short_en}/>
          );
        },
      };
    });
    const allPossibleValues = regionMetadata.map(({id}) => id);
    return {
      status: LoadableStatus.Present,
      data: {
        choices,
        allPossibleValues,
      },
    };
  }
};

interface IOwnProps {
  deselectedCategories: number[];
  setDeselected: (ids: number[]) => void;
  resetDeselected: () => void;
}
interface IStateProps {
  info: ILoadable<ISelectorInfo>;
}

type IProps = IOwnProps & IStateProps & DispatchProp;

interface IState {
  spotlightMode: SpotlightMode;
}
class RegionSelector extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      spotlightMode: SpotlightMode.Off,
    };
  }
  componentDidMount() {
    this.fetchData(this.props);
  }

  private fetchData({dispatch}: IProps) {
    dispatch(fetchIfNeeded({}));
  }

  private setSpotlightMode = (spotlightMode: SpotlightMode) => this.setState(
    (prevState: IState) => ({...prevState, spotlightMode}),
  )
  render() {
    const {info, deselectedCategories, setDeselected, resetDeselected} = this.props;
    const {spotlightMode} = this.state;

    let choices: IChoice[], allPossibleValues: number[];
    if (info.status === LoadableStatus.Present) {
      ({data: {choices, allPossibleValues}} = info);
    } else {
      choices = [];
      allPossibleValues = [];
    }
    return (
      <Selector
        allPossibleValues={allPossibleValues}
        choices={choices}
        deselectedCategories={deselectedCategories}
        setDeselected={setDeselected}
        resetDeselected={resetDeselected}
        renderChoices={regionSelectorRenderProp}
        spotlightMode={spotlightMode}
        setSpotlightMode={this.setSpotlightMode}
      />
    );
  }
}

const mapStateToProps: () => MapStateToProps<IStateProps, IOwnProps, IRootState> = () => {
  const getMetadata = getMetadataSelector();
  const getSelectorInfo = memoize(unmemoizeGetSelectorInfo);
  return (state: IRootState) => {
    const metadata = getMetadata(state, {});
    return {info: getSelectorInfo(metadata)};
  };
};

export default connect(mapStateToProps)(RegionSelector);
