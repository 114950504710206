import React from 'react';
import styled from 'styled-components';
import {
  localTooltipZIndexHighPriority,
} from '../cssVariables';
import {
  DisplayValueStatus,
  IDetailOverlayRow as IRow,
} from '../viz/Utils';

const tooltipFillColor = 'rgba(92, 100, 125, 0.9)';
const tooltipBorderColor = 'rgba(255, 255, 255, 0.2)';

// These are in `vw` units:
const tooltipWidth = 10;
const arrowHeight = tooltipWidth / 12;
const arrowWidth = arrowHeight;

// This is the root element for the highlight tooltip.
// Use a different `z-index` for hover tooltip:
export const Root = styled.div`
  --translation-x: 0;
  --translation-y: 0;
  --color: ${tooltipBorderColor};

  top: 0;
  left: 0;
  z-index: ${localTooltipZIndexHighPriority};
  position: absolute;
  width: ${tooltipWidth}vw;
  transform: translateX(calc(-50% + var(--translation-x))) translateY(calc(-100% + var(--translation-y)));
  background-color: ${tooltipFillColor};
  border: 1px solid ${tooltipBorderColor};
  border-top: 5px solid var(--color);

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-bottom: 0;
  }

  &::before {
    bottom: -${arrowHeight}vw;
    left: 50%;
    transform: translateX(-50%);
    border-top-color: ${tooltipBorderColor};
    border-width: ${arrowWidth}vw;
  }

  &::after {
    bottom: calc(-${arrowHeight}vw + 1px);
    left: 50%;
    transform: translateX(-50%);
    border-top-color: ${tooltipFillColor};
    border-width: calc(${arrowWidth}vw - 1px);
  }
`;

const ContentContainer = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(1.15rem, max-content);
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.8);
`;

const TitleContainer = styled.div`
  grid-column: 1 / span 2;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  padding: 0.5rem 0;
  color: white;
  text-align: center;
`;

const BodyText = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.65rem;
  padding: 0.25rem 0;
`;
const RegularHeading = styled(BodyText)`
  justify-content: flex-start;
`;
const RegularValue = styled(BodyText)`
  justify-content: flex-end;
`;

interface IProps {
  color: string;
  rows: IRow[];
  title: string;
  // These are the coordinates of the point we want to point to:
  x: number;
  y: number;
}

export default class extends React.PureComponent<IProps> {
  render() {
    const {rows, title, x, y, color} = this.props;

    const rowElems = rows.map(({label, value}) => {
      let displayedValue: string | number;
      if (typeof value === 'string' || typeof value === 'number') {
        displayedValue = value;
      } else {
        if (value.status === DisplayValueStatus.Show) {
          displayedValue = value.value;
        } else {
          displayedValue = 'N/A';
        }
      }

      return [
        <RegularHeading key='label'>{label}</RegularHeading>,
        <RegularValue key='value'>{displayedValue}</RegularValue>,
      ];
    });

    const style = {
      '--translation-x': `${x}px`,
      '--translation-y': `calc(${y}px - ${arrowHeight}vw)`,
      '--color': color,
    };

    return (
      <Root style={style}>
        <ContentContainer>
          <TitleContainer>{title}</TitleContainer>
          {rowElems}
        </ContentContainer>
      </Root>
    );
  }
}
